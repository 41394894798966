import { graphql } from 'gatsby';

import React, { Component } from 'react';
import { get, find } from 'lodash';

import Layout from '../layout/Layout';

import { PageHero, SectionTitle } from '../common/components/';
import GalleryImages from './GalleryImages';

interface Props {
  data: any;
  location:any;
}

class Gallery extends Component<Props, any> {
  render() {
    const { data } = this.props;
    const body = get(data, 'page.data.body');
    const galleryTitle = get(data, 'page.data.title');
    const heroData = find(body, { __typename: 'PrismicGalleryBodyHero' });
    const categoriesData = find(body, {
      __typename: 'PrismicGalleryBodyCategories',
    });
    const imagesData = find(body, {
      __typename: 'PrismicGalleryBodyImages',
    });
    const categoriesItems = get(categoriesData, 'items', null);
    const imagesItems = get(imagesData, 'items', null);

    return (
      <Layout data={data} location={this.props.location}>
        {heroData && <PageHero data={heroData} />}
        {galleryTitle && <SectionTitle title={galleryTitle} />}
        {categoriesItems && imagesItems && (
          <GalleryImages categories={categoriesItems} images={imagesItems} />
        )}
      </Layout>
    );
  }
}

export default Gallery;

// Query metadata for the homepage
export const query = graphql`
  query GalleryQuery($slug: String!) {
    page: prismicGallery(id: { eq: $slug }) {
      ...Gallery
    }
  }
`;
