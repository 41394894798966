import React, { Component } from 'react';
import { get, filter } from 'lodash';
import styled, { withTheme, css } from 'styled-components';
import Slider from 'react-slick';

import Img from 'gatsby-image';
import { Row, Col, Modal, Icon } from 'antd';
import Animate from 'rc-animate';
import breakpoint from 'styled-components-breakpoint';
import { window, exists } from 'browser-monads';
import { Text, FlowerPattern } from '../common/components';

import flowerGold1 from '../assets/flower-3-gold.svg';
import flowerGold2 from '../assets/flower-2-gold.svg';

const GalleryImagesContainer = styled.div`
  position: relative;
  margin-top: 30px;
  overflow: hidden !important;
  margin-bottom: 3rem;
`;

const FiltersCol = styled(Col)`
  display: flex !important;
  align-items: center !important;
  flex-direction: row !important;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
`;

const FilterContainer = styled.div`
  padding: 0 12px;
  :hover {
    cursor: pointer;
  }
`;

const FilterText = styled(Text)`
  text-align: center;
`;

const ImagesRow = styled(Row)`
  padding-left: 8vw;
  padding-right: 8vw;
`;

const ImageCol = styled(Col)`
  margin-bottom: 50px;
`;

const LightBoxModal = styled(Modal)`
  width: 80vw !important;
  height: auto !important;
  .ant-modal-content {
    background-color: transparent !important;
  }
  .ant-modal-close {
    top: 0% !important;
    right: 0% !important;
  }

  .ant-modal-close-x {
    ${breakpoint('xs')`
      font-size: 10px !important;
    `}
    ${breakpoint('sm')`
    font-size: 30px !important;
    `}
    
    color: white !important;
  }

  .slick-slider {
    padding: 50px 30px;
  }
`;

const GalleryImage = styled(Img)`
  ${breakpoint('xl')`
    max-height: 70vh !important;
  `}
  * {
    object-fit: ${props =>
      props.isSliderImage ? 'contain' : 'cover'}!important;
  }
  :hover {
    ${props => (props.isSliderImage ? '' : 'cursor: pointer;')}
  }
`;

const ImageNameContainer = styled.div`
  margin-top: 20px;
  text-align: center;
`;
const ImageName = styled(Text)``;

const CarouselArrowIcon = styled(Icon)`
  width: auto !important;
  height: auto !important;
  ${props =>
    props.type === 'right' ? 'margin-right: -40px;' : 'margin-left: -40px;'}
  font-size: 35px !important;
  top: 50% !important;
  z-index: 5;
  color: ${props => props.theme.color.text.main}!important;
  &:hover {
    color: ${props => props.theme.color.primary.main}!important;
  }

  ${props =>
    props.type &&
    (props.type === 'right' || props.type === 'left') &&
    css`
      ${props.type}: 25% !important;
      ${breakpoint('sm')`
        ${props.type}: 5px !important;
      `}
    `}
`;

const CarouselArrow = ({ type, ...props }) => (
  <CarouselArrowIcon
    type={type}
    onClick={props.onClick}
    className={props.className}
  />
);

interface Props {
  categories: any;
  images: any;
  theme: any;
}

interface State {
  selectedCategory: string;
  showLightbox: boolean;
  imagesToDisplay: any;
  activeSlide: number;
}

class GalleryImages extends Component<Props, State> {
  slider: any;

  constructor(props) {
    super(props);
    this.slider = React.createRef();

    this.state = {
      selectedCategory: 'all',
      imagesToDisplay: filter(props.images, imageData => {
        const imageSrc = get(
          imageData,
          'image.localFile.childImageSharp.fluid',
          null
        );

        if (!imageSrc) return false;
        return true;
      }),
      showLightbox: false,
      activeSlide: 0,
    };
  }

  filterImages = (selectedCategory: string) =>
    filter(this.props.images, imageData => {
      const imageSrc = get(
        imageData,
        'image.localFile.childImageSharp.fluid',
        null
      );

      const imgCat = get(
        imageData,
        'category.document[0].data.category_type',
        ''
      );

      if (
        !imageSrc ||
        (selectedCategory !== 'all' && imgCat !== selectedCategory)
      )
        return false;
      return true;
    });

  renderLightBoxCarousel = () => {
    const { theme } = this.props;
    const settings = {
      dots: false,
      infinite: true,
      speed: 800,
      autoPlay: false,
      autoplaySpeed: 1000,
      pauseOnHover: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      centerMode: true,
      centerPadding: '-2px',
      focusOnSelect: true,
      nextArrow: <CarouselArrow type="right" />,
      prevArrow: <CarouselArrow type="left" />,
    };

    return (
      <Col>
        <Slider {...settings} ref={slider => (this.slider = slider)}>
          {this.state.imagesToDisplay.map((imageData, idx) => {
            return this.renderImage({ ...imageData, isSlider: true }, idx);
          })}
        </Slider>
      </Col>
    );
  };
  renderCategories = () => {
    const { categories, theme } = this.props;

    return (
      <FiltersCol xs={24}>
        {categories.map((category, index) => {
          const catName = get(
            category,
            'category.document[0].data.category_name',
            ''
          );
          const catType = get(
            category,
            'category.document[0].data.category_type',
            ''
          );

          return (
            <FilterContainer
              key={index}
              onClick={e => {
                this.setState({
                  selectedCategory: catType,
                  imagesToDisplay: this.filterImages(catType),
                });
              }}
            >
              <FilterText
                color={
                  this.state.selectedCategory === catType
                    ? theme.color.text.main
                    : theme.color.secondary.main
                }
              >
                {catName}
              </FilterText>
            </FilterContainer>
          );
        })}
      </FiltersCol>
    );
  };

  renderImage = (imageData, index) => {
    const imageSrc = get(
      imageData,
      'image.localFile.childImageSharp.fluid',
      null
    );
    const imgAlt = get(imageData, 'image.alt', '');
    const imgCat = get(
      imageData,
      'category.document[0].data.category_type',
      ''
    );
    const aspect = { aspectRatio: imageData.isSlider ? 16 / 10 : 4 / 3 };
    return (
      <ImageCol
        xs={24}
        md={12}
        lg={8}
        key={index}
        onClick={() => {
          imageData.onClick ? imageData.onClick() : null;
        }}
      >
        <Animate transitionAppear transitionName="fade">
          <GalleryImage
            fluid={{
              ...imageSrc,
              ...aspect,
            }}
            alt={imgAlt}
            isSliderImage={!imageData.onClick}
            style={{ objectFit: 'contain' }}
          />
        </Animate>
        {imageData.displayName && (
          <ImageNameContainer>
            <ImageName>{imgAlt}</ImageName>
          </ImageNameContainer>
        )}
      </ImageCol>
    );
  };

  renderImages = () => {
    const { theme } = this.props;

    return (
      <ImagesRow gutter={50} type="flex" justify="start" align="middle">
        {this.state.imagesToDisplay.map((imageData, index) => {
          return this.renderImage(
            {
              ...imageData,
              ...{
                selectedCategory: this.state.selectedCategory,
                onClick: () => {
                  // Prevent useless lightBox on devices < Ipad width
                  if (
                    exists(window) &&
                    window.innerWidth >= theme.breakpoints.md
                  ) {
                    this.slider.slickGoTo(index);
                    this.setState({ showLightbox: true });
                  }
                },
              },
            },
            index
          );
        })}
      </ImagesRow>
    );
  };

  render() {
    return (
      <GalleryImagesContainer>
        <FlowerPattern
          src={flowerGold1}
          height="200px"
          width="auto"
          right="92%"
          bottom="5%"
          opacity={0.6}
        />
        <FlowerPattern
          src={flowerGold2}
          height="150px"
          width="auto"
          right="10px"
          bottom="50%"
          opacity={0.6}
        />
        <LightBoxModal
          title=""
          centered
          visible={this.state.showLightbox}
          onOk={() => this.setState({ showLightbox: false })}
          onCancel={() => this.setState({ showLightbox: false })}
          footer={null}
          forceRender={true}
          maskStyle={{ background: 'black', opacity: 0.8 }}
        >
          <Row>{this.renderLightBoxCarousel()}</Row>
        </LightBoxModal>
        <Row>{this.renderCategories()}</Row>
        {this.renderImages()}
      </GalleryImagesContainer>
    );
  }
}

export default withTheme(GalleryImages);
